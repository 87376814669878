<template>
  <li v-if="this.value1 !== undefined && this.value2 !== undefined" class="flex justify-content-start align-items-center py-1">  
    <div class="hidden sm:flex justify-content-center person-item m-0">
        <small class="">{{ titleStr }}</small>
    </div>
    <span :class="`person-tag ml-auto fg-lightGray p-1 line-height-3 text-sm ${showPointer1} ${this.col1}`" v-tooltip="`<span class='fg-lightYellow'>${value1.description}</span><hr class='mt-1 mb-1' />${timestamp_1}`" @click="toggle1" @dblclick="toggle">
      <i :class="'fi ' + icon_1 + ' icon-inline text-base mr-1 fg-amber'"></i>{{ value1PlusMinus }}{{ value_1.value }}<span class="text-xs unit">{{ unit_1 }}</span>
      <Menu :id="'valueMenu1_' + 1" ref="menu1" :model="valueMenu1" :popup="true" :baseZIndex="100" />
    </span>
    <span :class="`person-tag ml-1 fg-lightGray p-1 line-height-3 text-sm ${showPointer2} ${this.col2}`" v-tooltip="`<span class='fg-lightYellow'>${value2.description}</span><hr class='mt-1 mb-1' />${timestamp_2}`" @click="toggle2" @dblclick="toggle">
      <i :class="'fi ' + icon_2 + ' icon-inline text-base mr-1 fg-amber'"></i>{{ value2PlusMinus }}{{ value_2.value }}<span class="text-xs unit">{{ unit_2 }}</span>
      <Menu :id="'valueMenu2_' + 1" ref="menu2" :model="valueMenu2" :popup="true" :baseZIndex="100" />
    </span>
  </li>
  <dialog-chart-single :icon="icon_1" :node="value1" v-bind:show="showChart1" @chart-close="showChart1 = false"></dialog-chart-single>
  <dialog-multi-change :showDialog="showMultiChange1" :node="[value1]" @entryAbort="showMultiChange1 = false"></dialog-multi-change>
  <dialog-chart-single :icon="icon_2" :node="value2" v-bind:show="showChart2" @chart-close="showChart2 = false"></dialog-chart-single>
</template>

<script>
import { defineComponent } from "vue";
import { formatDateGui } from "@/helpers";
import dialogChartSingle from '@/components/dialog/chartSingle.vue';
import dialogMultiChange from '@/components/dialog/dialogMultiChange.vue';

export default defineComponent({
  name: "widgetRowValues2",
  setup() {},
  components: {
   dialogChartSingle,
   dialogMultiChange
  },
  props: {
    title: {
      type: String,
      required: false
    },
    value1: {
      type: null,
      required: false
    },
    icon1: {
      type: String,
      required: false
    },
    color1: {
      type: String,
      required: false
    },
    value1Timemode: {
      type: null,
      required: false
    },
    value2: {
      type: null,
      required: false
    },
    icon2: {
      type: String,
      required: false
    },
    color2: {
      type: String,
      required: false
    },
    value2Timemode: {
      type: null,
      required: false
    },
  },
  data() {
    return {
      loading: false,
      showChart1: false,
      showChart2: false,
      showMultiChange1: false,
      showMultiChange2: false,
    }
  },
  watch: {

  },
  computed: {
    titleStr: function () {
      if (this.title) return this.title;
      return `${this.value1.label} / ${this.value2.label}`;
    },
    value1_str: function () {
      if (this.value1.label) return this.value1.label;
      return "";
    },
    value2_str: function () {
      if (this.value2.label) return this.value2.label;
      return "";
    },
    col1: function () {
      if (this.color1 !== undefined) {
        return this.color1;
      } else {
        return '';
      }
    },
    col2: function () {
      if (this.color2 !== undefined) {
        return this.color2;
      } else {
        return '';
      }
    },
    value_1: function () {
      return this.parseValue(this.value1);
    },
    value_2: function () {
      return this.parseValue(this.value2);
    },
    unit_1: function () {
      return this.value1.unitStr;
    },
    unit_2: function () {
      return this.value2.unitStr;
    },
    timestamp_1: function () {
      if (this.value1.timestamp) {
        return formatDateGui(this.value1.timestamp);
      }
      return '';
    },
    timestamp_2: function () {
      if (this.value1.timestamp) {
        return formatDateGui(this.value2.timestamp);
      }
      return '';
    },
    icon_1: function () {
      if (this.value1.unit === "#HMS") {
        if (this.value1Timemode) {
          if (this.value1Timemode.value === 1) {
            return "fi-rr-sunrise-alt";
          } else if (this.value1Timemode.value === 2) {
            return "fi-rr-sunset";
          }
        }
        return "fi-rr-clock";
      }
      if (this.icon1) {
        return this.icon1;
      }
      return this.value1.icon;
    },
    icon_2: function () {
      if (this.value2.unit === "#HMS") {
        if (this.value2Timemode) {
          if (this.value2Timemode.value === 1) {
            return "fi-rr-sunrise-alt";
          } else if (this.value2Timemode.value === 2) {
            return "fi-rr-sunset";
          }
        }
        return "fi-rr-clock";
      }
      if (this.icon2) {
        return this.icon2;
      }
      return this.value2.icon;
    },
    value1PlusMinus: function () {
      if (this.value1Timemode !== undefined && this.value1Timemode.value > 0  && this.value1.unit === "#HMS") {
        if (this.value1.valueRaw < 0) return "-";
        else return "+";
      }
      return "";
    },
    value2PlusMinus: function () {
      if (this.value2Timemode !== undefined && this.value2Timemode.value > 0 && this.value2.unit === "#HMS") {
        if (this.value2.valueRaw < 0) return "-";
        else return "+";
      }
      return "";
    },
    isRecord1() {
      if (typeof(this.value1) === "object") {
        if (this.value1.record === "true") return true;
      }
      return false;
    },
    isWriteable1() {
      // if (typeof(this.value1) === "object") {
      //   if (this.value1.writeable === "true") return true;
      // }
      return false;
    },
    showPointer1() {
      if (this.isRecord1) return "cursor-pointer";
      else return "";
    },
    isRecord2() {
      if (typeof(this.value2) === "object") {
        if (this.value2.record === "true") return true;
      }
      return false;
    },
    isWriteable2() {
      // if (typeof(this.value2) === "object") {
      //   if (this.value2.writeable === "true") return true;
      // }
      return false;
    },
    showPointer2() {
      if (this.isRecord2) return "cursor-pointer";
      else return "cursor";
    },
    value1Contains() {
      if (this.isRecord1 || this.isWriteable1) return true;
      else return false;
    },
    valueMenu1() {
      let arr = [];
      if (this.isRecord1) {
        arr.push(
          {
            label: 'Statistik',
            icon: 'mif-chart-dots',
            command: () => {
              this.showChart('value1');
            }
          }
        )
      }
      if (this.isWriteable1) {
        arr.push(
          {
            label: 'Wert ändern',
            icon: 'mif-cog',
            command: () => {
              this.showMultiChange('value1');
            }
          }
        )
      }
      return arr;
    },
    value2Contains() {
      if (this.isRecord2 || this.isWriteable2) return true;
      else return false;
    },
    valueMenu2() {
      return [
        {
          label: 'Statistik',
          icon: 'mif-chart-dots',
          command: () => {
            this.showChart('value2');
          },
        },
      ];
    },
  },
  mounted() {
  },
  methods: {
    toggle1(event) {
      if (this.value1Contains) this.$refs.menu1.toggle(event);
    },
    toggle2(event) {
      if (this.value2Contains) this.$refs.menu2.toggle(event);
    },
    parseValue(val) {
      const value = { ...val };
      if (value.unit === '#BOOL') {
        if (value.value === true) value.value = '🟢Ein';
        else value.value = '🔴Aus';
      }
      return value;
    },
    showChart(value) {
      if (value === 'value1' && this.isRecord1) this.showChart1 = true;
      if (value === 'value2' && this.isRecord2) this.showChart2 = true;
    },
    showMultiChange(value) {
      if (value === 'value1' && this.isWriteable1) this.showMultiChange1 = true;
      if (value === 'value2' && this.isWriteable2) this.showMultiChange2 = true;
    },
  },
});
</script>

<style lang="scss" scoped>
.person-tag {
  // background: linear-gradient(45deg, rgba(15, 23, 42, 0.8), rgba(25, 39, 72, 0.8)) !important;
  // background: linear-gradient(45deg, rgba(39, 39, 39, 0.7), rgba(39, 39, 39, 0.5)) !important;
  background: linear-gradient(45deg, rgba(55, 60, 56, 0.7), rgba(55, 60, 56, 0.5)) !important;
  color: #fcfaf2 !important;
  box-shadow: 0 1px 0 0 rgba(255, 255, 255, .2) inset,
    0 0 0 1px rgba(0, 0, 0, .2),
    0 -1px 0 0 rgba(0, 0, 0, .2) inset,
    0 1px 2px 1px rgba(0, 0, 0, .1);
  border: none;
  outline: none;
  border-radius: 4px !important;
}
.person-tag:hover {
  box-shadow: 0 1px 0 0 rgba(255, 255, 255, .3) inset,
    0 0 0 1px rgba(0, 0, 0, .3),
    0 -1px 0 0 rgba(0, 0, 0, .3) inset,
    0 1px 2px 1px rgba(0, 0, 0, .2);
}
.person-tag:active {
  box-shadow: 0 0 0 1px rgba(0, 0, 0, .7) inset,
    0 1px 0 0 rgba(255, 255, 255, .05),
    0 1px 2px 1px rgba(0, 0, 0, .6) inset;
}
.person-tag .unit {
  color: #fedfe1 !important;
}
</style>